import * as React from 'react'
import Layout from '../components/Layout'

const AboutPage = () => {
  return (
    <Layout>
      <p>Who's there?</p>
    </Layout>
  )
}

export default AboutPage